<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('languages.new')">
        <actions
          slot="actions"
          crud-links="languages"
          :actions="actions"
        />
        <data-form
          :language="language"
          :loading="loading"
          @submit="submit"
        ></data-form>
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'languages-new',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      language: {
        id: 0,
        translation: {},
      },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'languages/'
    },
    async submit (language) {
      this.loading = true

      try {
        await this.$http.post(this.routeBuilder(), language)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'languagesIndex' })
    },
  },
}
</script>
